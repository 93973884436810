<template>
  <b-overlay
    variant="white"
    spinner-variant="primary"
    blur="0"
    opacity=".75"
    rounded="sm"
    :show="isLoading"
  >
    <div class="auth-wrapper auth-v2">
      <b-row class="auth-inner m-0">
        <b-link class="brand-logo">
          <Logo />
          <h2 class="brand-text text-primary ml-1">
            HOA LU UNIVERSITY
          </h2>
        </b-link>

        <b-col
          lg="8"
          class="d-none d-lg-flex align-items-center p-5"
        >
          <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
            <b-img
              fluid
              :src="imgUrl"
              alt="Nhập mã xác nhận"
            />
          </div>
        </b-col>

        <b-col
          lg="4"
          class="d-flex align-items-center auth-bg px-2 p-lg-5"
        >
          <b-col
            sm="8"
            md="6"
            lg="12"
            class="px-xl-2 mx-auto"
          >
            <b-card-title
              title-tag="h2"
              class="font-weight-bold mb-1"
            >
              Gửi mã xác nhận 🔒
            </b-card-title>
            <b-card-text class="mb-2">
              Nhập mã xác nhận
            </b-card-text>

            <!-- form -->
            <validation-observer
              ref="verifyOTPFormRef"
              #default="{invalid}"
            >
              <b-form
                class="auth-forgot-password-form mt-2"
                @submit.prevent="verify"
              >
                <b-form-group label-for="code">
                  <template v-slot:label>
                    Mã xác nhận <span class="text-danger">*</span>
                  </template>
                  <validation-provider
                    #default="{ errors }"
                    name="Mã xác nhận"
                    rules="required"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid':null"
                    >
                      <b-form-input
                        id="code"
                        v-model="code"
                        :state="getElementState(errors)"
                        name="code"
                        placeholder="Nhập mã xác nhận"
                      />
                      <b-input-group-append>
                        <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          size="sm"
                          variant="outline-primary"
                          @click="resendCode"
                        >
                          <span class="text-nowrap text-right">
                            <feather-icon icon="RefreshCwIcon" /> Gửi lại mã
                          </span>
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-button
                  type="submit"
                  variant="primary"
                  block
                  :disabled="invalid"
                >
                  Xác nhận
                </b-button>
              </b-form>
            </validation-observer>

            <p class="text-center mt-2">
              <b-link :to="{ name: 'login' }">
                <feather-icon icon="ChevronLeftIcon" /> Quay lại trang đăng nhập
              </b-link>
            </p>
          </b-col>
        </b-col>
      </b-row>
    </div>
  </b-overlay>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Logo from '@core/layouts/components/Logo.vue'
import {
  BRow,
  BCol,
  BLink,
  BCardTitle,
  BCardText,
  BImg,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BOverlay,
  BInputGroup,
  BInputGroupAppend,
} from 'bootstrap-vue'
import { required } from '@validations'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapActions } from 'vuex'
import Ripple from 'vue-ripple-directive'

export default {
  name: 'VerifyOTP',
  directives: {
    Ripple,
  },
  components: {
    BInputGroupAppend,
    BInputGroup,
    BOverlay,
    Logo,
    BRow,
    BCol,
    BLink,
    BImg,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BCardText,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      isLoading: false,
      code: '',
      sideImg: require('@/assets/images/pages/forgot-password-v2.svg'),
      email: '',
      required,
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/forgot-password-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  created() {
    const { email } = this.$route.query
    this.email = email
  },
  methods: {
    ...mapActions({
      verifyForgotPassword: 'authentication/verifyForgotPassword',
      forgotPassword: 'authentication/forgotPassword',
    }),
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
    async resendCode() {
      this.isLoading = true
      try {
        const response = await this.forgotPassword({ email: this.email })
        if (response) {
          const { isSuccessful, message } = response
          if (isSuccessful) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Gửi lại mã xác thực thành công!',
                icon: 'XCircleIcon',
                variant: 'success',
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          }
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async verify() {
      const valid = await this.$refs
        .verifyOTPFormRef
        .validate()
      if (valid) {
        this.isLoading = true
        try {
          const response = await this.verifyForgotPassword({ email: this.email, code: this.code })
          if (response) {
            const { isSuccessful, message } = response
            if (isSuccessful) {
              await this.$router.push({
                name: 'resetPassword',
                query: {
                  email: this.email,
                  code: this.code,
                },
              })
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            }
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
